import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-mini';
import { toast } from 'react-toastify';
import SimpleModal from '../modal/modal';
import AccountManager from '../../managers/Account';
import Throbber from '../throbber';

const emailPattern = /^[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;

class TransferNFT extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailAddress: '',
      confirmEmailAddress: '',
      searchResults: null,
      isLoading: false,
      selectedId: {},
      confirmState: false,
      transferLoading: false,
      creatorId: process.env.CREATOR_ID,
      step: 'email',
      firstname: '',
      lastname: '',
      message: '',
    };
  }

  validateForm = () => {
    const { emailAddress, confirmEmailAddress } = this.state;
    if (emailAddress === ''
      || confirmEmailAddress === '') {
      toast.error('Please fill out both email fields.');
      return false;
    }
    if (!emailPattern.test(emailAddress)) {
      toast.error('Please enter a valid email address.');
      return false;
    }
    if (emailAddress !== confirmEmailAddress) {
      toast.error('Emails must match.');
      return false;
    }
    return true;
  }

  changeStep = (step, e) => {
    if (e) {
      e.preventDefault();
    }
    if (this.validateForm()) {
      this.setState({
        step: 'personalize',
      });
    }
  }

  transferNFT = async () => {
    const { nft, onSuccess } = this.props;
    const {
      emailAddress, creatorId, firstname, lastname, message,
    } = this.state;
    const payload = {
      receivingUserEmail: emailAddress,
      nftId: nft.nftId,
      creatorId,
      receivingUserFirstname: firstname,
      receivingUserLastname: lastname,
      receivingUserMessage: message,
    };
    this.setState({
      isLoading: true,
    });

    // TODO: handle an error state
    const transferred = await AccountManager.transferNFtNotification(AccountManager.getToken(), payload);
    if (transferred && transferred.success) {
      toast.success('Successfully sent transfer notification!');
      onSuccess(nft);
    } else {
      if (transferred && transferred.message) {
        toast.error(transferred.message);
      } else {
        toast.error('Oops! Something went wrong. Please try again later.');
      }
      this.setState({
        isLoading: false,
      });
    }
  }

  confirmTransfer = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (this.validateForm()) {
      this.setState({
        confirmState: true,
      });
    }
  }

  showTransferButton = (user) => {
    this.setState({
      selectedId: user,
    });
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  }

  componentDidMount() {}

  render() {
    const { nft, onClose } = this.props;
    const {
      emailAddress, confirmEmailAddress, confirmState, isLoading, step, firstname, lastname, message,
    } = this.state;
    return (
      <div>
        <SimpleModal isOpen onBeforeClose={() => onClose()}>
          {isLoading
            && <Throbber throbberText="Transferring NFT! Please stand by..." />}
          
          {step === 'chooser'
            && (
            <div className="account-subheading">
              Transfer this NFT to another user, or your own crypto wallet
              <div className="form-section">
                <div className="form-row">
                  <div className="form-cta -align-center">
                    <button
                    className="button -block button-small -no-margin"
                    type="submit"
                    onClick={(e) => this.changeStep('personalize', e)}>
                      Transfer to another user
                    </button>
                    <div className="button-divider">OR</div>
                    <button
                    className="button -block button-small -no-margin"
                    type="submit"
                    onClick={(e) => this.changeStep('personalize', e)}>
                      Transfer to your crypto wallet
                    </button>
                  </div>
                </div>
              </div>
            </div>
            )}

          {step === 'email'
          && (
          <div className="account-subheading">
            Enter the email address of the person you would like to send this NFT&nbsp;to.
          </div>
          )}
          
          {step === 'personalize'
          && (
          <div className="account-subheading">
            Enter the first name and the last name of the person you would like to send this NFT to.
          </div>
          )}

          {step === 'email'
          && (
          <div>
            <div className="form-section">
              <label className="label-block" htmlFor="emailAddress">Email address:</label>
              <input
                  className="input-block"
                  id="emailAddress"
                  type="text"
                  value={emailAddress}
                  onChange={(e) => this.handleInputChange(e)}
                  disabled={confirmState} />
            </div>

            <div className="form-section">
              <label className="label-block" htmlFor="confirmEmailAddress">Confirm email address:</label>
              <input
                  className="input-block"
                  id="confirmEmailAddress"
                  type="text"
                  value={confirmEmailAddress}
                  onChange={(e) => this.handleInputChange(e)}
                  disabled={confirmState} />
            </div>
          </div>
          )}
          {step === 'personalize'
          && (
          <div>
            <div className="form-section">
              <div className="form-row">
                <div className="form-row__col">
                  <label className="label-block" htmlFor="firstname">
                    {'Enter recipient\'s first name'}
                    :
                  </label>
                  <input
                      className="input-block"
                      id="firstname"
                      type="text"
                      value={firstname}
                      onChange={(e) => this.handleInputChange(e)}
                      disabled={confirmState} />
                </div>
                <div className="form-row__col">
                  <label className="label-block" htmlFor="lastname">
                    {'Enter recipient\'s last name'}
                    :
                  </label>
                  <input
                      className="input-block"
                      id="lastname"
                      type="text"
                      value={lastname}
                      onChange={(e) => this.handleInputChange(e)}
                      disabled={confirmState} />
                </div>
              </div>
            </div>
            <div className="form-section">
              <label className="label-block" htmlFor="message">Enter a message for the recipient:</label>
              <textarea
                  className="input-block"
                  id="message"
                  type="text"
                  value={message}
                  onChange={(e) => this.handleInputChange(e)}
                  disabled={confirmState} />
            </div>
          </div>
          
          )}

          <div className="user-search__results">
            {confirmState
              ? (
                <div>
                  <div className="user-search__confirm">
                    <div>
                      Are you sure you want to transfer this nft to
                      {' '}
                      {emailAddress}
                      ? This action cannot be undone.
                    </div>
                    <button type="button" onClick={() => this.transferNFT()} className="button button-small button-confirm">Yes I am sure?</button>
                  &nbsp;
                    <button type="button" onClick={() => this.setState({ confirmState: false })} className="button button-small">Cancel</button>
                  </div>
                </div>
              )
              : (
                <div>
                  {step === 'email'
                  && (
                  <div className="form-cta">
                    <button
                    className="button button-small button-confirm -no-margin"
                    type="submit"
                    onClick={(e) => this.changeStep('personalize', e)}>
                      Next
                    </button>
                  </div>
                  )}
                  {step === 'personalize'
                  && (
                  <div className="form-cta">
                    <button
                    className="button button-small button-confirm -no-margin"
                    type="submit"
                    onClick={(e) => this.confirmTransfer(e)}>
                      Transfer NFT
                    </button>
                    &nbsp;
                    <button
                    className="button button-small -no-margin"
                    type="submit"
                    onClick={() => this.setState({ step: 'email' })}>
                      Back
                    </button>
                  </div>
                  )}
                </div>
              )}
              
          </div>
          {step !== 'chooser'
            && (
            <div>
              <div className="collection-details__item">
                NFT ID:&nbsp;
                <strong>{nft.nftId}</strong>
              </div>
                            
              <div className="collection-details__item">
                Date purchased:&nbsp;
                <strong>
                  {moment(nft.datePurchased).format('MMMM DD, YYYY')}
                </strong>
              </div>
            </div>
            )}
          
        </SimpleModal>
      </div>
    );
  }
}

TransferNFT.propTypes = {
  nft: PropTypes.string,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default TransferNFT;
