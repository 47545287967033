import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Countdown from 'react-countdown';
import moment from 'moment-mini';
import { Link } from 'react-router-dom';
import { urlEncode } from '@sentry/utils';

class HomeHero extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countdownData: null,
      friendlyDate: null,
    };
  }

  setHeroHeight = () => {
    const hero = document.getElementById('landingHero');
    if (hero) {
      hero.style.height = window.innerHeight + 'px';
    }
  }

  parseCountdown = (date) => {
    this.setState({
      countdownData: moment(date).unix() * 1000,
      friendlyDate: moment(date).format('ha  on dddd, MMMM D, YYYY'),
    });
  }

  componentDidMount() {
    const { data } = this.props;
    const that = this;
    if (process.env.BANNER_TYPE !== 'slim') {
      this.setHeroHeight();
      window.addEventListener('resize', that.setHeroHeight);
    }
    that.parseCountdown(data.drop.dateOpen);
  }

  learnMore = (e) => {
    const { onLearnMore } = this.props;
    e.preventDefault();
    onLearnMore();
  }

  goToFaq = (e) => {
    const { onFaqButton } = this.props;
    e.preventDefault();
    onFaqButton();
  }

  render() {
    const { type } = this.props;
    const { countdownData } = this.state;
    const renderer = ({
      days, hours, minutes, seconds, completed,
    }) => {
      if (completed) {
        window.location.href = '/';
      }
      // Render a countdown
      return (
        <div>
          <div className="countdown-item">
            {days}
            {' '}
            <span>days</span>
          </div>
          <div className="countdown-item">
            {hours}
            {' '}
            <span>hours</span>
          </div>
          <div className="countdown-item">
            {minutes}
            {' '}
            <span>mins</span>
          </div>
          <div className="countdown-item">
            {seconds}
            {' '}
            <span>secs</span>
          </div>
        </div>
      );
    };
    return (
      <div>
        <div
          id="landingHero"
          className={process.env.BANNER_TYPE === 'slim' ? 'landing-hero -fixed' : 'landing-hero'}
          style={{
            backgroundImage: 'url(' + process.env.HERO_BACKGROUND + ')',
          }}>
          <video className="hero-video" autoPlay="autoplay" playsInline loop muted>
            <source src={process.env.HERO_BACKGROUND_VIDEO} type="video/mp4" />
            <track
            default
            kind="captions"
            srcLang="en"
            src="" />
            Your browser does not support the video tag.
          </video>
          <div className="landing-hero__inner">
            {type === 'countdown' && process.env.HERO_LOGO
            && <div className="hero-title__logo"><img src={process.env.HERO_LOGO} alt="" /></div>}
            <div className="landing-hero__text -center-vert">
              {/* TODO: make this part of the env file */}
              <h1 className="hero-title">{process.env.HERO_TITLE}</h1>
              <h2 className="hero-subtitle">{process.env.HERO_SUBTITLE}</h2>
            </div>
          </div>
        </div>
        <div className="featured-drop__outer">
          <div className="countdown-container">
            <h2 className="countdown-header">{process.env.SITE_TITLE}</h2>
            {/* <div className="hero-header-cta">
              <a href="#" className="button" onClick={(e) => this.learnMore(e)}>Learn More</a>
            </div> */}
            <p
              className="hero-description"
              dangerouslySetInnerHTML={{ __html: process.env.SITE_DESCRIPTION }}>
            </p>
            {process.env.SITE_DESCRIPTION2
            && (
            <p
              className="hero-description"
              dangerouslySetInnerHTML={{ __html: process.env.SITE_DESCRIPTION2 }}>
            </p>
            )}
            {process.env.SITE_DESCRIPTION3
            && (
              <p
              className="hero-description"
              dangerouslySetInnerHTML={{ __html: process.env.SITE_DESCRIPTION3 }}>
              </p>
            )}
            {/* <p className="hero-description">
              NFTs will drop at
              {' '}
              {friendlyDate}
              .
            </p> */}
            {/* <a href="#" className="button" onClick={(e) => this.learnMore(e)}>Learn More</a> */}
            {type === 'countdown'
                && (
                <div className="hero-countdown__inline">
                  {countdownData
                  && (
                  <Countdown
                  date={countdownData}
                  renderer={renderer} />
                  )}
                </div>
                )}
            {/* {type === 'countdown'
                  ? <a href="#" className="button-hero" onClick={(e) => this.learnMore(e)}>Learn More</a>
                  : <Link className="button-hero" to={'/drop/' + data.drop.dropId}>View Details</Link>} */}
          </div>
        </div>
      </div>
    );
  }
}

HomeHero.propTypes = {
  data: PropTypes.objectOf(PropTypes.object),
  type: PropTypes.string,
  onLearnMore: PropTypes.func,
  onFaqButton: PropTypes.func,
};

export default HomeHero;
