import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { getUtilityType } from '../../managers/Helpers';
import AccountManager from '../../managers/Account';
import SimpleModal from '../modal/modal';
import Throbber from '../throbber';

const UtilityDisplay = ({ nftUtility, collectionUtility, onPlay }) => {
  const [formattedUtilities, setFormattedUtilities] = useState([]);
  const [formattedNFTUtilities, setFormattedNFTUtilities] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const formatUtilities = (utilities, type) => {
    const newArray = [];
    const utilitiesMax = 5;
    for (let i = 1, n = utilitiesMax; i < n; ++i) {
      if (utilities.filter((e) => e.utilityType === i).length > 0) {
        newArray.push({
          type: getUtilityType(i),
          items: utilities.filter((b) => b.utilityType === i),
        });
      }
    }
    if (type === 'collection') {
      setFormattedUtilities(newArray);
    } else {
      setFormattedNFTUtilities(newArray);
    }
  };
  const getGatedVideo = async (gatedContentId, e) => {
    e.preventDefault();
    setShowModal(true);
    setTimeout(async () => {
      const gatedContent = await AccountManager.getGatedContent(AccountManager.getToken(), gatedContentId);
      setShowModal(false);
      if (gatedContent && gatedContent.success) {
        onPlay(gatedContent.fileUrl);
      } else {
        toast.error('Oops. Something went wrong. Please try again later.');
      }
    }, 3000);
  };

  useEffect(() => {
    formatUtilities(collectionUtility, 'collection');
    formatUtilities(nftUtility, 'nft');
  }, []);
  return (
    <div>
      <SimpleModal isOpen={showModal} onBeforeClose={() => setShowModal(false)}>
        <Throbber throbberText="Checking for gate access.. Stand by." />
      </SimpleModal>
      {formattedUtilities.map((utility, index) => (
        <div className="utility-section" key={index}>
          <div>
            <h3>{utility.type}</h3>
            {utility.items.map((item, index) => (
              <div key={item.utilityId}>
                {item.gatedContents.map((gate, index) => (
                  <div key={gate.gatedContentId}>
                    <a href="#" onClick={(e) => getGatedVideo(gate.gatedContentId, e)}>{gate.description}</a>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      ))}
      {formattedNFTUtilities.map((utility, index) => (
        <div className="utility-section" key={index}>
          <div>
            <h3 className="utility-hdr">{utility.type}</h3>
            {utility.items.map((item) => (
              <div key={item.utilityId}>
                {item.utilityType === 1
                  ? (
                    <div>
                      {item.vouchers.map((voucher) => (
                        <div key={voucher.voucherId}>
                          <div>
                            {item.utilityDescription}
                            :
                          </div>
                          <div>
                            {voucher.voucherCode}
                          </div>
                        </div>
                      ))}
                    </div>
                  )
                  : <a href="#" onClick={(e) => getGatedVideo(item, e)}>{item.utilityDescription}</a>}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
export default UtilityDisplay;
