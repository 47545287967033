import React, { Component } from 'react';
import { Outlet } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TopNav from 'components/navigation/top-nav';
import Footer from 'components/navigation/footer';
import FullPageLoader from 'components/throbber/loader';
import AccountManager from 'managers/Account';
import SimpleModal from 'components/modal/modal';
import CreatorManager from 'managers/Creator';
import CookieConsent from 'react-cookie-consent';
import moment from 'moment-mini';
import ConfirmTransfer from './components/transfer/confirmTransfer';
import RouterListener from './components/router/routerListener';

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      dropDetails: null,
      showContent: false,
      showModal: false,
      mode: '',
      currentDate: moment().unix() * 1000,
      isFreeTransfer: false,
    };
  }

  getAccountDetails = async () => {
    await AccountManager.get(AccountManager.getToken());
  }

  cancelTransfer = (transferToken) => {
    const { isFreeTransfer } = this.state;
    if (isFreeTransfer) {
      localStorage.removeItem(process.env.CREATOR_SUBDOMAIN + 'FreeToken');
      localStorage.removeItem(process.env.CREATOR_SUBDOMAIN + 'Countdown');
      this.setState({
        showModal: false,
      });
    } else {
      sessionStorage.removeItem('NFT_TRANSFER_' + transferToken);
      // remove query string
      window.location.href = window.location.href.split('?')[0];
    }
  }

  onTransferError = (transferToken, message) => {
    toast.error(message);
    sessionStorage.removeItem('NFT_TRANSFER_' + transferToken);
    this.setState({
      showModal: false,
    });
  }

  transferNFT = async (transferToken, isFree, transferType) => {
    const payload = {
      transferToken,
    };
    let verified;
    if (isFree) {
      verified = await AccountManager.transferFreeNFt(AccountManager.getToken(), payload);
    } else if (transferType === 'eDrop') {
      verified = await AccountManager.transferEdrop(AccountManager.getToken(), payload);
    } else {
      verified = await AccountManager.transferNFt(AccountManager.getToken(), payload);
    }
    if (verified && verified.success) {
      if (isFree) {
        localStorage.removeItem(process.env.CREATOR_SUBDOMAIN + 'FreeToken');
        localStorage.setItem(process.env.CREATOR_SUBDOMAIN + 'PreRegTokenClaimed', true);
      } else {
        sessionStorage.removeItem('NFT_TRANSFER_' + transferToken);
      }
      gtag('event', process.env.CREATOR_SUBDOMAIN + '_edrop', { // eslint-disable-line
        event_category: process.env.CREATOR_SUBDOMAIN + '_edrop_success',
        event_label: 'User transferred a ' + process.env.CREATOR_SUBDOMAIN + ' eDrop NFT',
      });
      localStorage.setItem('NFT_TOKEN_TRANSFERRED_' + transferToken, transferToken);
      this.setState({
        showModal: false,
      });
      toast.success(verified.message);
    } else {
      sessionStorage.removeItem('NFT_TRANSFER_' + transferToken);
      this.setState({
        showModal: false,
      });
      toast.error(verified && verified.message ? verified.message : 'Sorry. This transfer is no longer available.');
    }
  }

  findTransferKey = () => {
    let nftToken = null;
    let tokenType = null;
    Object.entries(sessionStorage).map((obj) => {
      const key = obj[0];
      if (key.includes('NFT_TRANSFER')) {
        nftToken = key;
        tokenType = 'transfer';
      }
      return true;
    });
    Object.entries(localStorage).map((obj) => {
      const key = obj[0];
      if (key.includes('FreeToken')) {
        if (obj[1] !== 'already claimed') {
          nftToken = key;
          tokenType = 'free';
        }
      }
      return true;
    });
    return {
      token: nftToken,
      tokenType,
    };
  }

  getDropData = async () => {
    const that = this;
    const { currentDate } = this.state;
    const dropData = await CreatorManager.getDrop();
    let countdownDate;
    // Check if dropData.drop has a countdown set. If it doesn, always display the countdown page
    if (dropData.drop.countDown && dropData.drop.countDown.countDownDate) {
      countdownDate = moment(dropData.drop.countDown.countDownDate).unix() * 1000;
    }
    if (countdownDate > currentDate && (!window.location.href.includes('login') && !window.location.href.includes('reset') && !window.location.href.includes('register') && !window.location.href.includes('account'))) {
      window.location.pathname = '/countdown';
    } else if (dropData) {
      that.setState({
        dropDetails: dropData,
        showContent: true,
        isLoading: false,
      });
    }
  }

  checkForRiskified = () => {
    const that = this;
    if (typeof RISKX === 'undefined') {
      console.log('RISKX failed to initialize.');
      setTimeout(() => {
        that.checkForRiskified();
      }, 100);
    } else {
      AccountManager.setSessionId();
    }
  }

  componentDidMount = async () => {
    this.checkForRiskified();
    // check if the user is logged in. If they are, grab the account details
    let transferKey = null;
    // check if this transferCode has already been claimed
    let alreadyClaimed = false;
    if (AccountManager.isLoggedIn()) {
      if (this.findTransferKey()) {
        transferKey = this.findTransferKey();
        if (localStorage.getItem('NFT_TOKEN_TRANSFERRED_' + sessionStorage.getItem(transferKey.token))) {
          alreadyClaimed = true;
        }
        if (!alreadyClaimed) {
          if (transferKey.tokenType === 'transfer') {
            this.setState({
              transferToken: sessionStorage.getItem(transferKey.token),
            });
            if (sessionStorage.getItem(transferKey.token)) {
              this.setState({
                showModal: true,
                mode: 'confirmTransfer',
                isFreeTransfer: transferKey.tokenType === 'free',
              });
            }
          } else {
            this.setState({
              transferToken: localStorage.getItem(transferKey.token),
            });
            if (localStorage.getItem(transferKey.token)) {
              this.setState({
                showModal: true,
                mode: 'confirmTransfer',
                isFreeTransfer: transferKey.tokenType === 'free',
              });
            }
          }
        } else {
          sessionStorage.removeItem(transferKey.token);
        }
      }
      await this.getAccountDetails();
    }

    // TODO: Get the drop details now
    if (process.env.E_DROP_ONLY !== 'true') {
      await this.getDropData();
    } else {
      this.setState({
        showContent: true,
        isLoading: false,
      });
    }
  }

  render() {
    const {
      isFreeTransfer, isLoading, dropDetails, showContent, showModal, mode, transferToken,
    } = this.state;
    return (
      <div>
        <RouterListener />
        <SimpleModal isOpen={showModal} onBeforeClose={() => this.setState({ showModal: false })}>
          {mode === 'confirmTransfer'
            && (
            <ConfirmTransfer
                  isFree={isFreeTransfer}
                  nftToken={transferToken}
                  onConfirm={(token, isFree, transferType) => this.transferNFT(token, isFree, transferType)}
                  onCancel={(token) => this.cancelTransfer(token)}
                  onError={(token, message) => this.onTransferError(token, message)} />
            )}
        </SimpleModal>
        {isLoading
          ? (
            <div>
              <FullPageLoader />
            </div>
          )
          : (
            <div>
              <ToastContainer
                position="top-right"
                autoClose={5000}
                pauseOnHover
                theme="light" />
              <TopNav dropDetails={dropDetails} />
              {showContent && !showModal
                && <Outlet />}
              {process.env.CREATOR_SUBDOMAIN !== 'account'
              && <Footer />}
              <CookieConsent
                cookieName={process.env.CREATOR_SUBDOMAIN + 'CookieConsent'}>
                <a href="https://www.ethosnft.com">ethos</a>
                {' '}
                uses cookies to provide necessary website functionality,
                improve your experience and analyze our traffic. By using our website, you agree to&nbsp;
                <a target="_blank" href="https://www.ethosnft.com/privacy" rel="noreferrer">our privacy policy</a>
                {' '}
                and our cookies usage.
                
              </CookieConsent>
            </div>
          )}
      </div>
    );
  }
}

export default Container;
